.navigator{
    height: 100vh;
    background:rgb(247, 250, 247);
    float:left;
    left: 0;
    width: 20%;    
    /* border-radius: 12px;
  padding: 5px; */
  position: absolute;
  z-index: 1000;
    
}

ul.nav.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    
    
}

.list-group{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
  
}

.list-group-item{
  background-color: white !important;
  color:white;
  border: none;
}

.Mui-disabled{

  background-color: darkblue;
}

/* .MuiTypography-root {
  color:black;
  align-items: center;
} */
