
.gadolbox{
    height: 85vh;
    background:rgb(247, 250, 247);
    float:right;
    right: 0;
    width: 20%;    
     position: absolute;
  z-index: 1000;
   border-radius:0px; 
}

.customCard {
  background-color: rgb(230, 238, 238) !important;
}

.gadolName{
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
}

.gadolFact{
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.MuiPaper-root {
  background-color: rgb(230, 238, 238) !important;
  color: black !important;
  
 
}

.MuiIconButton-root {
  color: white !important;
 
}